import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'


// Bootstrap CSS and JS
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'

//Font-awesome Icon
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'

//CK Editor
import CKEditor from '@ckeditor/ckeditor5-vue';

//Sweet Alert 2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// datatable 
import 'jquery/dist/jquery'
import 'datatables.net-bs5/js/dataTables.bootstrap5'
import 'datatables.net/js/jquery.dataTables'
import 'datatables.net-responsive-bs5/js/responsive.bootstrap5'
import 'datatables.net-buttons-bs5/js/buttons.bootstrap5'
import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.css'
const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);
app.use(CKEditor);
app.use(VueSweetalert2);
app.mount('#app');
