<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="text-danger">Page Not Found !!!!</h1>
      </div>
    </div>
  </div>
  <div class="container" v-if="loading">
    <div class="row">
      <div class="col">
        <Spinner />
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '../components/Spinner.vue';
export default {
  name: "PageNotFound",
  components: {
    Spinner
  }

}
</script>
<style>

</style>