// localStorage.setItem("authToken", thisObj.permissionupdate.token);
import axios from "./axios.js"

// const token = "1|ku7lIj2vaSsk0iYkGbytMxN5FCu6sdojX6fM2fG0";


export class Auth {


    static login(login) {
        let defaultURL = `/login`;
        return axios.post(defaultURL, login);
    }
    static logout() {
        let defaultURL = `/logout`;
        let $user = axios.post(defaultURL);
        // this.$router.push("/login")
        console.log($user);
    }


}