<template>
  <div style="display: none;">{{ store.isLogin }}</div>
  <!-- {{ $route.meta.islogin }} -->
  <NavBar :userName="userName" :isLogin="isLogin" />

  <!-- v-if="!$route.meta.hideNavbar" -->

  <router-view />
</template>
<script>
import NavBar from './components/NavBar.vue'
import { useUserStore } from './stores/userstore'
export default {
  name: "App",
  components: {
    NavBar


  },
  setup() {
    const store = useUserStore();

    return {
      store
    }
  },
  data() {
    return {
      isLogin: this.store.isLogin,
      userName: 'User Name',
      display: false
    }
  },
  updated() {
    this.checklogin();
    // console.log('App Upated');
  },
  created() {
    this.checklogin();
    // console.log('App Created');
  },
  mounted() {
    this.checklogin();
    // console.log('App Mounted');
  },
  computed: {
    // isRegister(){
    //   return this.$route.meta.register;
    // }
  },
  methods: {

    checklogin() {
      // console.log(isLogin);
      const token = localStorage.getItem('authToken');
      if (token) {
        this.isLogin = true;
        // console.log(this.isLogin);
        // console.log(isLogin);
        this.userName = localStorage.getItem('userName');
        // console.log(this.$route.name );
        if (this.$route.name == "Login") {
          this.$router.push('/');
        }
      } else {
        this.isLogin = false
        this.store.isLogin = false;
        // alert(this.isRegister());
        // alert(this.$route.meta.register);
        this.$router.push('/login');
      }
      if (this.$route.name == "Logout") {
        this.isLogin = false;
      }
    },
    changeLogin(islogin) {
      alert(islogin);
      this.islogin = islogin;
    }
  },
  watch: {

  }

}
</script>
<style>

</style>
