<template lang="">
 <!-- {{ isLogin }} -->
 <div v-if="isLogin">
<div class="header-main-top">
   <header class="p-3 mb-3 border-bottom">
    <div class="container">
      
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <!-- <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
          <svg class="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlink:href="#bootstrap"/></svg>
        </a> -->
        <a class="navbar-brand px-2" href="#"><h2>Power Fasteners</h2></a>
        <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          <li><router-link to="/" class="nav-link px-2 link-dark"><i class="fa fa-home" aria-hidden="true"></i>
 Home</router-link></li>
          <li><router-link to="/vendor" class="nav-link px-2 link-dark"><i class="fa fa-users" aria-hidden="true"></i>
 Vendor</router-link></li>
           <div class="dropdown text-end mt-2 px-2">
          <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-tasks" aria-hidden="true"></i>
 Masters
          </a>
          <ul class="dropdown-menu text-small ">
           <li><router-link to="/country" class="dropdown-item">Country</router-link></li>
           <li><router-link to="/state" class="dropdown-item">State</router-link></li>
           <li><router-link to="/allcity" class="dropdown-item">All City</router-link></li>           
            <li><hr class="dropdown-divider"></li>
            <li><router-link to="/group" class="dropdown-item">Group</router-link></li>
            <li><router-link to="/city" class="dropdown-item">City</router-link></li>             
          </ul>
        </div> 
        <div class="dropdown text-end mt-2 px-2">
          <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-envelope" aria-hidden="true"></i>
 Mails
          </a>
          <ul class="dropdown-menu text-small ">           
           <li><router-link to="/mail-send" class="dropdown-item">Send Mail</router-link></li>
           <li><router-link to="/mail-template" class="dropdown-item">Mail Template</router-link></li>
           <li><router-link to="/mail-filters" class="dropdown-item">Mail Recipient</router-link></li>
            <li><hr class="dropdown-divider"></li>
            <!-- <li><router-link to="/mail-settings" class="dropdown-item">Settings</router-link></li> -->
            
            <li><router-link to="/mail-send-job" class="dropdown-item">Mail Logs</router-link></li>            
          </ul>
        </div>
        </ul>
        
        <div class="dropdown text-end">
          <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
           <img src="../assets/no-user.png" alt="mdo" width="32" height="32" class="rounded-circle"> <span class="px-1">{{ userName }}</span>
          </a>
          <ul class="dropdown-menu text-small">
            <!-- <li><a class="dropdown-item" href="#">New project...</a></li>
            <li><a class="dropdown-item" href="#">Settings</a></li>
            <li><a class="dropdown-item" href="#">Profile</a></li>
            <li><hr class="dropdown-divider"></li> -->
            <li><button class="btn btn-link dropdown-item" @click="handleLogout" ><i class="fa fa-power-off" aria-hidden="true"></i>
 Logout   </button></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  </div>
 </div>

</template>
<script>
import { Auth } from '../services/Auth'
import { useUserStore } from '../stores/userstore';
export default {
  name: 'NavBar',
  components: {

  },
  props: {
    userName: String,
    isLogin: Boolean
  },
  setup() {
    const store = useUserStore();

    return {
      store
    }
  },
  data() {
    return {


    }
  },
  created() {

  },
  updated() {

  },
  methods: {
    async handleLogout() {
      const token = await Auth.logout();
      localStorage.removeItem('authToken');
      this.store.isLogin = false;
      this.$emit('changeLogin', false);
      // alert(this.isLogin);
      // alert('logout');
      this.$router.push('/login');
      setTimeout(function() {
                    location.reload(true);
                }, 100);
      // alert('logout');
      console.log(token);
    },
    checklogin() {
      if (!this.isLogin) {
        alert('login');
        this.$router.push('/login');
      }
    }
  }

}
</script>
<style scoped>
    .header-main-top {
    background: lightskyblue;
    box-shadow: 0px 15px 10px -15px #def2ff;
}
</style>