import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../views/PageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      islogin: false,
     }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    meta: {
      islogin: false,
     }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: {
      islogin: false,
      register : true
     }
  },
  {
    path: '/country',
    name: 'Country',
    component: () => import('../views/CountryMaster.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/state',
    name: 'State',
    component: () => import('../views/StateMaster.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/city',
    name: 'City',
    component: () => import('../views/CityMaster.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/allcity',
    name: 'AllCity',
    component: () => import('../views/AllCityMaster.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/group',
    name: 'Group',
    component: () => import('../views/GroupMaster.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/vendor',
    name: 'Vendor',
    component: () => import('../views/VendorMaster.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/mail-template',
    name: 'MailTemplate',
    component: () => import('../views/MailTemplate.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/mail-send',
    name: 'SendMail',
    component: () => import('../views/SendMail.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/mail-settings',
    name: 'MailSettings',
    component: () => import('../views/MailSettings.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/mail-filters',
    name: 'MailFilter',
    component: () => import('../views/MailFilter.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/mail-logs',
    name: 'MailLog',
    component: () => import('../views/MailLogs.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/mail-send-job',
    name: 'MailSendJob',
    component: () => import('../views/MailSendJob.vue'),
    meta: {
      islogin: true,
     }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
// router.beforeEach((to, from, next) => {
  // If the user is not logged in, redirect to /login
  // const isLoggedIn = localStorage.getItem('authToken');
  // if (isLoggedIn) {
  //   return next({ path: "/login" });
  // }

  // next();
// });
export default router
