<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="home">
          WelCome, {{ userName }}<br />
          <!-- <button class="btn btn-success" >Test</button> <i class="fa fa-home fa-4x text-success"></i> -->
          <!-- <img alt="Vue logo" src="../assets/logo.png"> -->

        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      userName: localStorage.getItem('userName')
    }
  },
  mounted() {
    // location.reload();

  },
}
</script>
