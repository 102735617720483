<template>
    <div class="container loading">
        <div class="row">
            <div class="col">
                <div>
                    <img src="../assets/loading-text.gif" alt="loading-gif" class="d-block m-auto" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Spinner',
    props: {

    },


}
</script>
<style scoped>
.loading {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);

    z-index: 999;



}
</style>